.main-loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;

  &__box {
    display: inline-block;
    text-align: center;
  }

  &__svg {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: loader-animation;
    animation-timing-function: linear;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' preserveAspectRatio='xMidYMid meet' style='fill: none;' viewBox='0 0 100 100'%3E%3Cdefs%3E%3ClinearGradient id='loader-gradient-up-1' x1='1' x2='0' y1='0' y2='0'%3E%3Cstop offset='0' stop-opacity='1'%3E%3C/stop%3E%3Cstop offset='100%25' stop-opacity='0.5'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='loader-gradient-down-1'%3E%3Cstop offset='0' stop-opacity='0.5'%3E%3C/stop%3E%3Cstop offset='100%25' stop-opacity='0'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg stroke-width='3%25'%3E%3Cpath stroke='url(%23loader-gradient-up-1)' d='M95 50A45 45 0 1 0 5 50'%3E%3C/path%3E%3Cpath stroke='url(%23loader-gradient-down-1)' d='M5 50A45 45 0 1 0 95 50'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    display: inline-flex;
    height: 100px;
    position: relative;
    transform-origin: center;
    transition-property: stroke;
    width: 100px;
  }

  &__label {
    text-align: center;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
